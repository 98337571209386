/* import React, { useEffect, useState } from "react";
import { Descriptions } from "antd";

const DescriptionComponent = ({ title, data }) => {
    return (
        <div className="mt-5" style={{ color: "white" }}>
            <h2>{title}</h2>

            <Descriptions bordered column={1} className="descriptions-white">
                {Object.entries(data).map(([key, value], index) => (
                    <Descriptions.Item
                        key={key}
                        label={key}
                        className={
                            index % 2 === 0
                                ? "table-row-light"
                                : "table-row-white"
                        }
                    >
                        {typeof value === "boolean"
                            ? value
                                ? "Présence"
                                : "Absence"
                            : typeof value === "object"
                            ? Object.entries(value).map(
                                  ([beneficiary, percentage]) => (
                                      <div key={beneficiary}>
                                          <strong>{beneficiary}:</strong>{" "}
                                          {percentage}
                                      </div>
                                  )
                              )
                            : value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
        </div>
    );
};

export default DescriptionComponent;
 */
import React, { useEffect, useState } from "react";
import { Descriptions } from "antd";

const DescriptionComponent = ({ title, data }) => {
    const renderValue = value => {
        if (typeof value === "boolean") {
            return value ? "Présence" : "Absence";
        } else if (typeof value === "object") {
            if (Array.isArray(value)) {
                return value.map((item, index) => (
                    <div key={index}>
                        {typeof item === "object" ? (
                            Object.entries(item).map(([subKey, subValue]) => (
                                <div key={subKey}>
                                    <strong>{subKey}:</strong>{" "}
                                    {String(subValue)}
                                </div>
                            ))
                        ) : (
                            <div>{String(item)}</div>
                        )}
                    </div>
                ));
            } else {
                return Object?.entries(value)?.map(([subKey, subValue]) => (
                    <div key={subKey}>
                        <strong>{subKey}:</strong> {renderValue(subValue)}
                    </div>
                ));
            }
        } else {
            return String(value);
        }
    };

    return (
        <div className="mt-5" style={{ color: "white" }}>
            <h2>{title}</h2>

            <Descriptions bordered column={1} className="descriptions-white">
                {Object.entries(data).map(([key, value], index) => (
                    <Descriptions.Item
                        key={key}
                        label={key}
                        className={
                            index % 2 === 0
                                ? "table-row-light"
                                : "table-row-white"
                        }
                    >
                        {renderValue(value)}
                    </Descriptions.Item>
                ))}
            </Descriptions>
        </div>
    );
};

export default DescriptionComponent;
