import React, { Component } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ConfigProvider } from "antd";

import createStore from "./redux/store";
import { history } from "./redux/enhancers/middlewares/router";
import Layout from "./containers/Layout/Layout";
import "./styles/style.scss";
import { I18nextProvider } from "react-i18next";

import ConfigTranslation from "./translations";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Router } from "react-router-dom/cjs/react-router-dom.min";
/*import LogRocket from "logrocket";
LogRocket.init("wltld8/cmr-portail");*/

import locale from "antd/locale/fr_FR";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import ScrollToTop from "./utils/ScrollToTop";
dayjs.locale("fr");
class App extends Component {
    render() {
        console.log("Front E-client version 19/09/2024 01:00");
        return (
            <Provider store={createStore()}>
                <Router history={history}>
                    <ScrollToTop />
                    <I18nextProvider i18n={ConfigTranslation}>
                        <ConfigProvider
                            locale={locale}
                            theme={{
                                token: {
                                    fontFamily: "Lexend",
                                    colorPrimary: "#046DB2"
                                    // colorSecondary: "#f58e40"
                                },
                                components: {
                                    Radio: {
                                        buttonSolidCheckedActiveBg: "#046DB2",
                                        buttonSolidCheckedBg: "#046DB2",
                                        buttonSolidCheckedHoverBg: "#046DB2"
                                    }
                                }
                            }}
                        >
                            <Layout />
                        </ConfigProvider>
                        <ToastContainer />
                    </I18nextProvider>
                </Router>
            </Provider>
        );
    }
}

export default App;
